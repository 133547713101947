/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'typeface-dancing-script'
import 'typeface-open-sans'

// You can delete this file if you're not using it
